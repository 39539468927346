<template>
  <div>
    <div class="d-flex justify-space-between">
      <p class="font-weight-bold title black--text">Ucapan dari Influencer</p>
      <!-- <p class="primary--text text-14 pt-1 font-weight-bold">Lihat Lainnya</p> -->
    </div>
    <div class="row">
      <v-slide-group
        class="pb-4 row pl-3 pt-3"
        active-class="success"
        >
        <v-slide-item
            v-for="n in 5"
            :key="n"
        >
          <v-card
            width="250px"
            height="240px"
            class="round ma-3"
            elevation="0"
            outlined
          >
            <v-card-text class="pa-2 pb-0">
                <v-list-item class="grow pl-0 pb-2">
                    <v-list-item-avatar color="accent">
                        <v-img
                            class="elevation-6"
                            alt=""
                            src="https://cdn.vuetifyjs.com/images/carousel/bird.jpg"
                        ></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title><p class="black--text text-14 font-weight-bold mb-0 mt-1">Aifa Putri Davina</p></v-list-item-title>
                        <v-list-item-title><p class="mb-1 text-12">Influencer</p></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
              
              <center>
                <v-img class="pb-0 round" width="230" height="150" src="https://cdn.vuetifyjs.com/images/carousel/bird.jpg" />
              </center>
            </v-card-text>
          </v-card>
        </v-slide-item>
      </v-slide-group>
  </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props:['data'],
  setup() {
    return {
      isLoading:true,
      categories:[
        {name:'Tiktok', icon:'fab fa-tiktok'},
        {name:'Beauty', icon:'fas fa-spa'}, 
        {name:'Food & Beverages', icon:'fas fa-hamburger'},
        {name:'Travel & Lifestyle', icon:'fas fa-plane'},
      ],
      categoriesB:[
        {name:'Parenting', icon:'fas fa-baby'}, 
        {name:'Home Decor', icon:'fas fa-laptop-house'}, 
        {name:'Fashion', icon:'fas fa-tshirt'}, 
        {name:'Lihat Semua', icon:'fas fa-th-large'}, 
      ]
    }
  },
  mounted(){
    // this.loadImage()
  },
  methods:{
    loadImage(){
      let data = this.data
      this.isLoading = true;
      let token = localStorage.getItem('token')
      for(let a=0; a < data.length; a++){
        axios({
          url: process.env.VUE_APP_API_ENDPOINT + 'influencers/getImage/'+data[a].photo,
          headers: {
            'Authorization': 'Bearer '+ token
          },
          method: 'GET',
          responseType: 'blob',
        }).then((res) => {
          const blob = new Blob([res.data], { type: "application/JPG" });
          data[a].photo = URL.createObjectURL(blob);
        });
      }
      this.data = data
      setTimeout(() => { 
        this.isLoading = false
      }, 500);
    },
  },
  filters:{
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    }
  }
}
</script>
